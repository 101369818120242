/* eslint-disable jsx-a11y/iframe-has-title */
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
// import { SMTPClient } from 'emailjs';
import { useEffect } from "react";

const ContactUs = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2uy277e",
        "template_sul3s1h",
        e.target,
        "Teh35-Dpz_b-AgxGR"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (err) {
          console.log("FAILED...", err);
        }
      );
  };
  // Document title
  document.title = "RoviaGate Technology, LLC | Contact Us";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-3">
            Contact
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Contact Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Contact Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInDown"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h6 className="section-title bg-white text-center text-primary px-3">
              Contact Us
            </h6>
            <h4 className="display- mb-4">Please Feel Free, Contact Us</h4>
          </div>
          <div className="row g-0 justify-content-center">
            <div className="col-lg-10 wow fadeInDown" data-wow-delay="0.5s">
              <p className="text-center mb-4">
                RoviaGate Technology is a ICT based Software company, where we
                do websites, software and many more. We're open to any inquiries
                and quotes from our lovely customers.{" "}
              </p>
              <form onSubmit={sendEmail}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        required
                        autoComplete="off"
                      />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Your Email"
                        required
                        autoComplete="off"
                      />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required
                        autoComplete="off"
                      />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        name="comment"
                        placeholder="Leave a message here"
                        id="message"
                        style={{ height: "200px" }}
                        required
                      ></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>

                  {/* <input name="_formsubmit_id" type="text" style={{ display: "none" }}></input> */}
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary rounded-pill py-3 px-5"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}

      {/* <!-- Google Map Start --> */}
      <div className="container-xxl pt-4 pb-4 wow fadeIn" data-wow-delay="0.1s">
        <iframe
          className="w-100 mb-n2"
          style={{ height: "450px" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.783056076102!2d-10.780487685157363!3d6.29221832755424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf0a0347d511ca5f%3A0x3fdd9eae1690ac03!2sRoviaGate%20Technology%20LLC!5e0!3m2!1sen!2s!4v1652881983796!5m2!1sen!2s"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
      {/* <!-- Google Map End --> */}
    </>
  );
};

export default ContactUs;
