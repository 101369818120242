import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';

const NewsList = ({ newsList }) => {
    // const { data } = props;
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 3;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(newsList.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(newsList.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, newsList]);
    // console.log(pageCount)
    // console.log(newsList);

    // console.log(currentItems);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % newsList.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="col-lg-12 col-md-12">
                {currentItems ? (currentItems.map(newsList => {
                    return (
                        <div className="row justify-content-center mb-4">
                            <div className="col-lg-10 col-md-6 h-100" key={newsList.id}>
                                <h5>{newsList.title}</h5>
                                <div className="news-meta mb-3">
                                    <span className="news-author">Monrovia, Liberia </span>
                                    <span className="news-date"> Jul 9, 2022</span>
                                </div>
                                <p className="mb-3 text-dark">A RoviaGate Technology eVerify© Module Find Officer® is a mobile/desktop-based application. As a background, the Millennium Challenge Corporation (MCC) was established in January 2004 as a United States government corporation. MCC’s mission is to reduce poverty by supporting economic growth in developing countries that create and maintain sound policy environments. MCC is designed to support innovative strategies and ensure accountability for measurable results.</p>
                                <p className="mb-3 text-dark">A RoviaGate Technology eVerify© Module Find Officer® is a mobile/desktop-based application. As a background, the Millennium Challenge Corporation (MCC) was established in January 2004 as a United States government corporation. MCC’s mission is to reduce poverty by supporting economic growth in developing countries that create and maintain sound policy environments. MCC is designed to support innovative strategies and ensure accountability for measurable results.</p>
                                <p className="mb-3 text-dark">A RoviaGate Technology eVerify© Module Find Officer® is a mobile/desktop-based application. As a background, the Millennium Challenge Corporation (MCC) was established in January 2004 as a United States government corporation. MCC’s mission is to reduce poverty by supporting economic growth in developing countries that create and maintain sound policy environments. MCC is designed to support innovative strategies and ensure accountability for measurable results.</p>

                                <div className="news-meta mb-3">
                                    <span className="news-author">
                                        <Link to="/news" style={{ color: '#E46C0B', fontWeight: 'bold' }}>READ MORE</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })) : 'Loading'}
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel={null}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={null}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                activeLinkClassName="active"
            />
        </>
    );
}

export default NewsList;