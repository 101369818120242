import { Link } from "react-router-dom";
import { useEffect } from "react";

const Services = () => {
  // Document title
  document.title = "RoviaGate Technology, LLC | Our Services";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-3">
            Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Our Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Service Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h6 className="section-title bg-white text-center text-primary px-3">
              Our Services
            </h6>
            <h4 className="display- mb-4">
              We specialize in the following services
            </h4>
          </div>
          <div className="row mt-5" id="what-we-do">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="card">
                <div className="card-block block-1">
                  <h4 className="card-title">Application Development</h4>
                  <p className="card-text">
                    Database design and development, Web Application
                    development, Mobile Application development, Desktop
                    Application development, Website design, Hospital management
                    system, School Management System, Payroll Management System,
                    Full Biometric System (installation, deployment,
                    configuration, and maintenance).
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="card">
                <div className="card-block block-2">
                  <h4 className="card-title">
                    Equipment and Software Supplies
                  </h4>
                  <p className="card-text">
                    Computers (Desktop/Laptops – HP, Dell, Lenovo, etc.) <br />{" "}
                    Servers (HP, Dell, Meraki, Ubiquiti, etc.) <br /> Switches,
                    Routers, Access Points, Access Control, CCTV (Cisco, HP,
                    Dell, Meraki, Lenovo, HikVision, etc.) <br />
                    Microsoft, Antivirus (Symantec, Kaspersky, McAfee, Norton,
                    etc.)
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="card">
                <div className="card-block block-4">
                  <h4 className="card-title">Business Solution & Assessment</h4>
                  <p className="card-text">
                    One size does not fits all. We listen before we process and
                    we spread out to every size of businesses.
                    Corporate/Business training packages. <br />
                    Specialized training for public and private sector. <br />
                    Project Management (Training & Application).
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.6s"
            >
              <div className="card">
                <div className="card-block block-5">
                  <h4 className="card-title">
                    Consulting & Project Management
                  </h4>
                  <p className="card-text">
                    Consultants analyze your operations, provide strategic
                    recommendations assist in implementing solutions to tackle
                    challenges, and drive growth. We also believe that every
                    project is an important contribution to our journey in the
                    digital market.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.9s"
            >
              <div className="card">
                <div className="card-block block-8">
                  <h4 className="card-title">School Management System</h4>
                  <p className="card-text">
                    It is a secured web-based solution that aims to make
                    educational and administrative tasks easier. The system can
                    be used by both private schools as well as public schools.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="card">
                <div className="card-block block-6">
                  <h4 className="card-title">Data Center/Enterprise Systems</h4>
                  <p className="card-text">
                    Fully integrate all your business processes with our
                    enterprise solutions services. Increase efficiency, revenue
                    streams & productivity with our enterprise services. Global
                    Network. Specialized Professionals.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="card">
                <div className="card-block block-3">
                  <h4 className="card-title">Network Design & Installation</h4>
                  <p className="card-text">
                    Local Area Network (LAN)
                    <br /> Wide Area Network (WAN) <br />
                    Wireless Local Area Network (WLAN) <br />
                    Hybrid Data Center
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="1.0s"
            >
              <div className="card">
                <div className="card-block block-9">
                  <h4 className="card-title">Automated Integration System</h4>
                  <p className="card-text">
                    Automated Integrating system that communicates with all
                    other critical software your business.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.8s"
            >
              <div className="card">
                <div className="card-block block-7">
                  <h4 className="card-title">HR Automated Payroll System</h4>
                  <p className="card-text">
                    VoxHR is a Social all-in-one HR software that covers
                    entirely from "Hire"-"Retire" for the most important asset
                    in your company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/*  */}
      <section className="pt-0">
        <div className="container">
          <div className="bg-dark rounded position-relative overflow-hidden p-4 p-sm-7">
            {/* <!-- SVG decoration --> */}
            <div className="position-absolute top-0 start-0 mt-n8 mt-xl-n5 d-none d-md-block">
              <img
                src="/assets/img/service.svg"
                className="rotate-33 h-200px"
                alt=""
              />
            </div>
            {/* <!-- SVG decoration --> */}
            <div className="position-absolute end-0 bottom-0 d-none d-lg-block">
              <img
                src="/assets/img/cta-vector.svg"
                className="h-200px h-xl-300px"
                alt=""
              />
            </div>
            <div className="row g-4 position-relative">
              {/* <!-- Title and inputs --> */}
              <div className="col-lg-10 col-xl-7 mx-auto text-center">
                {/* <!-- Title --> */}
                <h3 className="text-white mb-3">
                  Ready to boost your business?
                </h3>
                <p className="text-white opacity-8">
                  Join the digital world today and be part of the millions
                  who've already embraced a brighter future!
                </p>
                {/* <!-- Button --> */}
                <Link to="/contact-us" className="btn btn-primary mb-6">
                  Get started today
                </Link>
              </div>
            </div>
            {/* <!-- Row END --> */}
          </div>
        </div>
      </section>
      {/*  */}

      {/*  */}
      <section className="pt-0">
        <div className="container">
          <div className="row g-4">
            {/* <!-- Testimonials --> */}
            <div className="col-lg-5">
              <div className="card card-body bg-dark p-sm-5">
                {/* <!-- Quote icon --> */}
                <span className="display-5 text-primary">
                  <i className="fas fa-quote-right"></i>
                </span>
                <p className="text-white fs-5">
                  During the Ebola epidemic, RoviaGate proposed an e-government
                  platform-as-a-service that automated the entire work permit
                  process, from application to insurance.
                </p>
                {/* <!-- Star --> */}
                <ul className="list-inline mb-0">
                  <li className="list-inline-item me-0">
                    <i className="bi bi-star-fill text-white fs-6"></i>
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="bi bi-star-fill text-white fs-6"></i>
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="bi bi-star-fill text-white fs-6"></i>
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="bi bi-star-fill text-white fs-6"></i>
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="bi bi-star-fill text-white fs-6"></i>
                  </li>
                </ul>
                <hr className="opacity-1 my-4" />
                {/* <!-- Divider --> */}

                {/* <!-- Avatar --> */}
                <div className="d-sm-flex align-items-center">
                  {/* <!-- Avatar --> */}
                  <div className="avatar flex-shrink-0">
                    <img
                      className="avatar-img rounded-circle"
                      src="/assets/img/neto.jpg"
                      alt="avatar"
                    />
                  </div>
                  {/* <!-- Info --> */}
                  <div className="ms-sm-2 mt-2 mt-sm-0">
                    <h6 className="text-white mb-0">Neto Zarzar Lighe</h6>
                    <p className="mb-0 small text-white">
                      Minister of Labour (Fmr.), Republic of Liberia
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Contact detail --> */}
            <div className="col-lg-7 ps-lg-5">
              {/* <!-- Title --> */}
              <h2 className="mb-4">See how we've helped our clients succeed</h2>

              {/* <!-- Features --> */}
              <div className="row g-4 g-lg-5">
                <div className="col-md-6">
                  <div className="card card-body bg-transparent p-0">
                    <div className="icon-lg bg-primary bg-opacity-10 text-primary rounded mb-3">
                      <i className="fas fa-fire fa-1x"></i>
                    </div>
                    <h6 className="mb-2">Customer Support</h6>
                    <p className="mb-0">
                      Effortlessly and effective customer experience for your
                      business solutions.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card card-body bg-transparent p-0">
                    <div className="icon-lg bg-primary bg-opacity-10 text-primary rounded mb-3">
                      <i className="fas fa-handshake fa-1x"></i>
                    </div>
                    <h6 className="mb-2">Best solution</h6>
                    <p className="mb-0">
                      We give the best services for business solution to our
                      clients.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card card-body bg-transparent p-0">
                    <div className="icon-lg bg-primary bg-opacity-10 text-primary rounded mb-3">
                      <i className="fas fa-clock fa-1x"></i>
                    </div>
                    <h6 class="mb-2">24/5 Service Available</h6>
                    <p class="mb-0">
                      We are available 24 hours of the day, 5 working days in a
                      week.
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card card-body bg-transparent p-0">
                    <div class="icon-lg bg-primary bg-opacity-10 text-primary rounded mb-3">
                      <i class="bi bi-shield-fill-check fa-1x"></i>
                    </div>
                    <h6 class="mb-2">100% Result Guarantee</h6>
                    <p class="mb-0">
                      We deliver as we promise our clients and turn over the
                      solution
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Row END --> */}
        </div>
      </section>
      {/*  */}
    </>
  );
};

export default Services;
