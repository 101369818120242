import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Services from "./pages/Services";
import News from "./pages/News";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Faq from "./pages/Faq";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <div>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/what-we-do" exact element={<Services />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/about-us" exact element={<AboutUs />} />
            <Route path="/faq" exact element={<Faq />} />
            <Route path="/news" exact element={<News />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
