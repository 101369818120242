import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import NewsList from "../components/NewsList";

const News = () => {
    // Document title
    document.title = "RoviaGate Technology, LLC | Our Story";

    // Get index of the last page
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {        
        fetch('https://roviagatetechnology.com/api/news_list').then(
            response => response.json()
        ).then(
            data => {
                // console.log(data.news_list);
                setNewsList(data.news_list)
            }
        )
    }, []);
    return (
        <>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container text-center py-5">
                    <h1 className="display-4 text-white animated slideInDown mb-3">News</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><Link className="text-white" to="/">Home</Link></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">News</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* <!-- Page Header End --> */}

            {/* <!-- Project Start --> */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        {NewsList && <NewsList newsList={newsList} />}
                    </div>
                </div>
            </div>
            {/* <!-- Project End --> */}
        </>
    );
}

export default News;