import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Faq = () => {
  // Document title
  document.title = "RoviaGate Technology, LLC | FAQ";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-3">
            FAQ
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                FAQ
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/*  */}
      <section class="pt-0 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 mx-auto">
              <h3 class="text-center mb-5">
                People usually ask these questions about our platform
              </h3>
              {/* <!-- Accordion START --> */}
              <div
                class="accordion accordion-icon accordion-bg-light"
                id="accordionFaq"
              >
                {/* <!-- Item --> */}
                <div class="accordion-item mb-3">
                  <div class="accordion-header font-base" id="heading-1">
                    <button
                      class="accordion-button fw-semibold rounded collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-1"
                      aria-expanded="true"
                      aria-controls="collapse-1"
                    >
                      What products or services does your company offer?
                    </button>
                  </div>
                  {/* <!-- Body --> */}
                  <div
                    id="collapse-1"
                    class="accordion-collapse collapse show"
                    aria-labelledby="heading-1"
                    data-bs-parent="#accordionFaq"
                  >
                    <div class="accordion-body mt-3 pb-0">
                      RoviaGate provides the planning, designing,
                      implementation, and maintenance of your ICT
                      infrastructure; our technology experts maintain a deep
                      knowledge of available and emerging technologies, which
                      results in the ability to offer our clients the best
                      solutions. As a tech company, we offer a range of
                      innovative products and services designed to meet the
                      diverse needs of our customers. These include cloud
                      computing platforms (IaaS), software as a service (SaaS)
                      applications, network security systems, cybersecurity
                      solutions, IT consultancy services, and more.
                    </div>
                  </div>
                </div>

                {/* <!-- Item --> */}
                <div class="accordion-item mb-3">
                  <div class="accordion-header font-base" id="heading-2">
                    <button
                      class="accordion-button fw-semibold rounded collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      How do I contact support if I encounter technical issues?
                    </button>
                  </div>
                  {/* <!-- Body --> */}
                  <div
                    id="collapse-2"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading-2"
                    data-bs-parent="#accordionFaq"
                  >
                    <div class="accordion-body mt-3 pb-0">
                      We analyze your operations, provide strategic
                      recommendations assist in implementing solutions to tackle
                      challenges, and drive growth. If you encounter technical
                      issues with our products or services, we provide multiple
                      avenues for you to contact our support team for
                      assistance. You can reach out to us via phone or email,
                      also we're available 24/7 through live chat on our
                      website.
                    </div>
                  </div>
                </div>

                {/* <!-- Item --> */}
                <div class="accordion-item mb-3">
                  <div class="accordion-header font-base" id="heading-3">
                    <button
                      class="accordion-button fw-semibold collapsed rounded"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      Do you offer ongoing updates and maintenance for your
                      products?
                    </button>
                  </div>
                  {/* <!-- Body --> */}
                  <div
                    id="collapse-3"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading-3"
                    data-bs-parent="#accordionFaq"
                  >
                    <div class="accordion-body mt-3 pb-0">
                      Yes, we provide ongoing updates and maintenance for all
                      our products. We understand the importance of keeping our
                      products up-to-date with the latest features, security
                      patches, and performance improvements. Our team regularly
                      releases updates to address any issues, enhance
                      functionality, and ensure compatibility with evolving
                      technologies.
                    </div>
                  </div>
                </div>

                {/* <!-- Item --> */}
                <div class="accordion-item mb-3">
                  <div class="accordion-header font-base" id="heading-4">
                    <button
                      class="accordion-button fw-semibold collapsed rounded"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-4"
                      aria-expanded="false"
                      aria-controls="collapse-4"
                    >
                      How do I get started with your products or services?
                    </button>
                  </div>
                  {/* <!-- Body --> */}
                  <div
                    id="collapse-4"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading-4"
                    data-bs-parent="#accordionFaq"
                  >
                    <div class="accordion-body mt-3 pb-0">
                      Getting started with our products or services is simple
                      and straightforward:{" "}
                      <b>
                        Explore Our Offerings, Request a Demo or Trial, Contact
                        Sales, Training and Onboarding, Support and Maintenance,
                        Feedback and Continuous Improvement.
                      </b>{" "}
                      By following these steps, you can get started with our
                      products or services and embark on a journey towards
                      achieving your business goals more efficiently and
                      effectively. If you have any further questions or need
                      assistance, please don't hesitate to contact us. We're
                      here to help!
                    </div>
                  </div>
                </div>

                {/* <!-- Item --> */}
                <div class="accordion-item mb-3">
                  <div class="accordion-header font-base" id="heading-5">
                    <button
                      class="accordion-button fw-semibold collapsed rounded"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-5"
                      aria-expanded="false"
                      aria-controls="collapse-5"
                    >
                      What type of training or onboarding is provided for new
                      clients?
                    </button>
                  </div>
                  {/* <!-- Body --> */}
                  <div
                    id="collapse-5"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading-5"
                    data-bs-parent="#accordionFaq"
                  >
                    <div class="accordion-body mt-3 pb-0">
                      For new clients, we provide comprehensive training and
                      onboarding to ensure a smooth transition and successful
                      implementation of our products or services.
                      <br />
                      Corporate/Business training packages. <br />
                      Specialized training for public and private sector. <br />
                      Project Management (Training & Application).
                    </div>
                  </div>
                </div>

                {/* <!-- Item --> */}
                <div class="accordion-item mb-3">
                  <div class="accordion-header font-base" id="heading-5">
                    <button
                      class="accordion-button fw-semibold collapsed rounded"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-5"
                      aria-expanded="false"
                      aria-controls="collapse-5"
                    >
                      Do you offer free trials or demos of your products?
                    </button>
                  </div>
                  {/* <!-- Body --> */}
                  <div
                    id="collapse-5"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading-5"
                    data-bs-parent="#accordionFaq"
                  >
                    <div class="accordion-body mt-3 pb-0">
                      Our goal with offering free trials or demos is to empower
                      you to make an informed decision and ensure that our
                      products or services meet your specific needs and
                      requirements. If you're interested in trying out our
                      solution, please don't hesitate to reach out to us. We
                      look forward to assisting you!
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Accordion END --> */}
            </div>
          </div>
        </div>
      </section>
      {/*  */}

      {/*  */}
      <section className="pt-0 pb-5">
        <div className="container">
          <div className="bg-dark rounded position-relative overflow-hidden p-4 p-sm-7">
            {/* <!-- SVG decoration --> */}
            <div className="position-absolute top-0 start-0 mt-n8 mt-xl-n5 d-none d-md-block">
              <img
                src="/assets/img/service.svg"
                className="rotate-33 h-200px"
                alt=""
              />
            </div>
            {/* <!-- SVG decoration --> */}
            <div className="position-absolute end-0 bottom-0 d-none d-lg-block">
              <img
                src="/assets/img/cta-vector.svg"
                className="h-200px h-xl-300px"
                alt=""
              />
            </div>
            <div className="row g-4 position-relative">
              {/* <!-- Title and inputs --> */}
              <div className="col-lg-10 col-xl-7 mx-auto text-center">
                {/* <!-- Title --> */}
                <h3 className="text-white mb-3">
                  Ready to boost your business?
                </h3>
                <p className="text-white opacity-8">
                  Join the digital world today and be part of the millions
                  who've already embraced a brighter future!
                </p>
                {/* <!-- Button --> */}
                <Link to="/contact-us" className="btn btn-primary mb-6">
                  Get started today
                </Link>
              </div>
            </div>
            {/* <!-- Row END --> */}
          </div>
        </div>
      </section>
      {/*  */}
    </>
  );
};

export default Faq;
